style="min-width: 100px;
<template>
	<Dialog
		v-if="quotationPopDialog"
		:dialog="quotationPopDialog"
		title="Project Detail"
		current
		:dialogWidth="1000"
		v-on:close="$emit('close')"
	>
		<template v-slot:title>
			<div class="d-flex justify-space-between w-100">
				<div>
					{{ detailDialogData?.title }}
					<!-- <v-tooltip top content-class="custom-top-tooltip">
						<template v-slot:activator="{ on, attrs }">
							<v-chip
								:color="getStatusColor(detailDialogData.priority_value)"
								label
								class="ml-2"
								v-bind="attrs"
								v-on="on"
								outlined
							>
								{{ detailDialogData.priority_value }}
							</v-chip>
						</template>
						<span> Priority Value </span>
					</v-tooltip> -->
				</div>
				<div>
					<v-tooltip top content-class="custom-top-tooltip">
						<template v-slot:activator="{ on, attrs }">
							<v-chip
								:color="getStatusColor(detailDialogData.status_value)"
								label
								v-bind="attrs"
								v-on="on"
								outlined
							>
								{{ detailDialogData.status_value }}
							</v-chip>
						</template>
						<span> Status </span>
					</v-tooltip>
				</div>
			</div>
		</template>
		<template v-slot:body>
			<div class="d-flex justify-space-between">
				<div style="width: 33%">
					<div class="d-flex">
						<div class="fw-500 mr-1 mb-1" style="min-width: 130px">Reference:</div>
						<ShowValue :object="detailDialogData" object-key="reference" label="reference"></ShowValue>
					</div>
					<div class="d-flex">
						<div class="fw-500 mr-1 mb-1" style="min-width: 130px">Amount:</div>
						<ShowPrice
							truncate
							:object="detailDialogData"
							object-key="total"
							label="quotation total"
						></ShowPrice>
					</div>
					<div class="d-flex">
						<div class="fw-500 mr-1 mb-1" style="min-width: 130px">Description :</div>
						<!-- <ShowTextAreaValue style="width: 150px;" :object="detailDialogData" object-key="description" label="description">
						</ShowTextAreaValue> -->
						<ShowValue
							style="width: 11rem"
							:object="detailDialogData"
							object-key="description"
							label="description"
						></ShowValue>
					</div>
				</div>

				<div style="width: 33%" v-if="detailDialogData?.project?.uuid">
					<div
						style="background-color: rgb(245 244 228); height: 100%; border-radius: 0.5rem"
						class="p-4"
					>
						<div class="font-level-4-bold mb-2 d-flex justify-space-between" style="color: #0d47a1">
							<span>Project</span>
							<div>
								<Chip
									tooltip
									tooltip-text="Project #"
									small
									:text="detailDialogData?.project?.barcode"
									color="blue darken-4"
								></Chip>
							</div>
						</div>
						<div class="d-flex">
							<div class="fw-500 mr-1 mb-1" style="min-width: 130px">Name:</div>
							<span v-if="detailDialogData.project?.name">{{ detailDialogData?.project?.name }}</span>
							<em v-else class="text-muted fw-400">No project name</em>
						</div>
						<div class="d-flex">
							<div class="fw-500 mr-1 mb-1" style="min-width: 130px">Start Date:</div>
							<div class="fw-400" v-if="detailDialogData?.project.start_date">
								{{ formatDate(detailDialogData?.project.start_date) }}
							</div>
							<em v-else class="text-muted">no start date</em>
						</div>
						<div class="d-flex">
							<div class="fw-500 mr-1 mb-1" style="min-width: 130px">End Date:</div>
							<div class="fw-400" v-if="detailDialogData?.project.end_date">
								{{ formatDate(detailDialogData?.project.end_date) }}
							</div>
							<em v-else class="text-muted">no end date</em>
						</div>
						<div class="d-flex">
							<div class="fw-500 mr-1 mb-1" style="min-width: 130px">Project Value:</div>
							<ShowPrice
								truncate
								:object="detailDialogData"
								object-key="project.project_value"
								label="project value"
							></ShowPrice>
						</div>
						<div class="d-flex">
							<div class="fw-500 mr-1 mb-1" style="min-width: 130px">Project Budget:</div>
							<ShowPrice
								truncate
								:object="detailDialogData"
								object-key="project.cost"
								label="project budget"
							></ShowPrice>
						</div>
					</div>
				</div>
				<div v-if="detailDialogData?.customer?.uuid" style="width: 33%">
					<div
						style="background-color: rgb(235 240 240); height: 100%; border-radius: 0.5rem"
						class="p-4"
					>
						<div class="font-level-4-bold mb-2 d-flex justify-space-between" style="color: #0d47a1">
							<span>Customer</span>
							<div>
								<Chip
									tooltip
									tooltip-text="Customer #"
									small
									:text="detailDialogData?.customer?.barcode"
									color="blue darken-4"
								></Chip>
							</div>
						</div>
						<div class="d-flex">
							<div class="fw-500 mr-1 mb-1" style="min-width: 130px">Name:</div>
							<ShowValue
								:object="detailDialogData.customer"
								object-key="display_name"
								label="name"
							></ShowValue>
						</div>
						<div class="d-flex">
							<div class="fw-500 mr-1 mb-1" style="min-width: 130px">Email:</div>
							<ShowValue
								:object="detailDialogData.customer"
								object-key="email"
								label="display name"
							></ShowValue>
						</div>
						<div class="d-flex">
							<div class="fw-500 mr-1 mb-1" style="min-width: 130px">Phone:</div>
							<ShowValue :object="detailDialogData.customer" object-key="phone" label="phone"></ShowValue>
						</div>
						<div class="d-flex">
							<div class="fw-500 mr-1 mb-1" style="min-width: 130px">Address:</div>
							<div class="d-flex flex-column">
								<div v-if="detailDialogData?.customer?.address_line_1">
									{{ detailDialogData?.customer?.address_line_1 }}
								</div>
								<div v-if="detailDialogData?.customer?.address_line_2">
									{{ detailDialogData?.customer?.address_line_2 }}
								</div>
								<div v-if="detailDialogData?.customer?.address_country">
									{{ detailDialogData?.customer?.address_country }}
								</div>
								<span v-if="detailDialogData?.customer?.address_postal_code">
									{{ detailDialogData?.customer?.address_postal_code }}
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</template>
		<template v-slot:action>
			<v-btn depressed tile v-on:click="$emit('close')"> Close </v-btn>
		</template>
	</Dialog>
</template>

<script>
import Dialog from "@/view/components/Dialog";
import ShowPrice from "@/view/components/ShowPrice";
import ShowValue from "@/view/components/ShowValue";
import Chip from "@/view/components/Chip";
// import ShowTextAreaValue from "@/view/components/ShowTextAreaValue";

export default {
	name: "Calendar-task",
	title: "Calendar-task",
	components: {
		Dialog,
		ShowPrice,
		// ShowTextAreaValue,
		ShowValue,
		Chip,
	},
	props: {
		quotationPopDialog: {
			type: Boolean,
			default: false,
		},
		detailDialogData: {
			type: Object,
			default: () => {},
		},
	},
};
</script>
