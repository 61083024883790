import ApiService from "@/core/services/api.service";
export const getCalendarData = (type, params) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.query(`calender/${type}`, params)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};
